
html, body {
  font-family: "Inter", sans-serif;
  padding: 0;
  margin: 0;
  width: 100%;
}

html{
  overflow-x: hidden;
  width:100vw;
}

.header-text-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("https://static.vecteezy.com/system/resources/previews/002/299/512/original/abstract-blue-wavy-lines-on-white-background-vector.jpg");
  background-position: center;
  background-size: 100vw 100%;
}

.header h1 {
  font-size: 5vw;
  font-weight: 900;
 /* background-color: #000;*/
  color: #fff;
  display: block;
  white-space: nowrap;
  width: 100%
}

.header h3 {
  font-size: 2vw;
  font-weight: 900;
  /* background-color: #000;*/
  color: #fff;
  display: block;
  padding: 0.5em;
  white-space: nowrap;
  width: 100%
}

.header p {
  font-size: 2vw;
  font-weight: 900;
  margin-top: 1em;
  text-align: center;
}
.header p span {
  display: block;
  margin-top: 0.25em;
}

.header {
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#yor{
  position: absolute;
  bottom: 0;
  right: 0;
  height: 98%;
}

.header a{
  text-decoration: none;
}

.info-box{
  z-index: 2;
  min-height: 100%;
}

.text-justify {
  text-align: justify;
}


.info-back-button, .info-link{
  cursor: pointer;
}

#footer a{
  text-decoration: none;
}


#headerbg{
  width: 100vw;
  height: 30vh;
}

