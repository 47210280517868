.Calculator-display-calculated-drink-calculate-drink-tile h1{
    font-size: max(2vw, 30px);
}

.Calculator-display-calculated-drink-calculate-drink-tile h2{
    font-size: max(1.5vw, 20px);
}

.Calculator-display-calculated-drink-calculate-drink-tile h3{
    font-size: max(1vw, 15px);
}

.Calculator-display-calculated-data h1{
    font-size: max(2vw, 25px);
}

.helicopter-link, .helicopter-link:hover {
    color: #ffffff;
    cursor: default;

}